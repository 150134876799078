import React, { useMemo, useState } from 'react';
import { getFirestore, addDoc, collection } from 'firebase/firestore/lite';

import CTAMockupLight from '../images/cta-mockup-light.png';
import CTAMockupDark from '../images/cta-mockup-dark.png';

const EarlyAccess = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isAnyError = useMemo(() => {
    return Object.keys(error).reduce((result, fieldName) => (
      result || !!error[fieldName]
    ), false)
  }, [error]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let error = false;

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
      setError((err) => ({ ...err, email: null }));
    } else {
      error = true;
      setError((err) => ({ ...err, email: 'Invalid Email' }));
    }

    if (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(data.phoneNumber)) {
      setError((err) => ({ ...err, phoneNumber: null }));
    } else {
      error = true;
      setError((err) => ({ ...err, phoneNumber: 'Invalid Phone Number' }));
    }

    if (!data.socialMediaUrl || /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(data.socialMediaUrl)) {
      setError((err) => ({ ...err, socialMediaUrl: null }));
    } else {
      error = true;
      setError((err) => ({ ...err, socialMediaUrl: 'Invalid url' }));
    }

    if (error) {
      setIsLoading(false);
      return;
    }

    const db = getFirestore(window.app);
    await addDoc(collection(db, "website_leads"), { ...data, requestTime: new Date() });
    setIsLoading(false);
    setIsSubmitted(true);
    e.target.reset();

    setTimeout(() => {
      setData({});
      setError({});
      setIsSubmitted(false);
    }, 4000);
  }

  const onFieldChange = (e, fieldName) => {
    e.preventDefault();
    setData(d => ({ ...d, [fieldName]: e.target.value }));
    setError(d => ({ ...d, [fieldName]: null }));
  }

  return (
    <section id="early-access" className="py-12 sm:py-16 lg:py-20 bg-primary-100 dark:bg-primary-900">
      <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-12 gap-x-24">
          <div>
            <p className="text-sm font-bold tracking-widest uppercase text-primary-500 dark:text-primary-300">
              Early Access is live!
            </p>
            <h3 className="mt-6 text-3xl font-bold text-gray-900 dark:text-white">
              Start earning like a star!
            </h3>
            <p className="mt-4 text-lg font-medium leading-8 text-gray-500 dark:text-gray-300">
              Join us in Early Access as a Creator and play a part in helping us shape the future of Social Media!
            </p>

            <form onSubmit={onSubmit} className="grid grid-cols-1 mt-8 lg:mt-12 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div className="sm:col-span-2">
                <label htmlFor="full-name" className="block text-sm font-semibold text-gray-900 dark:text-white">
                  Full Name *
                </label>
                <div className="mt-1">
                  <input type="text" name="full-name" id="full-name" autoComplete="given-name"
                    onChange={e => onFieldChange(e, "name")}
                    className="block w-full px-4 py-3 font-medium text-gray-900 border-gray-300 rounded-lg shadow-sm dark:text-white focus:ring-secondary-500 focus:border-secondary-500" />
                    {
                      error["name"] && (
                        <div className="py-2 text-sm text-red-500 font-bold">
                          {error["name"]}
                        </div>
                      )
                    }
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="social-url" className="block text-sm font-semibold text-gray-900 dark:text-white">
                  Social Media URL
                </label>
                <div className="mt-1">
                  <input type="url" name="social-url" id="social-url"
                    onChange={e => onFieldChange(e, "socialMediaUrl")}
                    className="block w-full px-4 py-3 font-medium text-gray-900 border-gray-300 rounded-lg shadow-sm dark:text-white focus:ring-secondary-500 focus:border-secondary-500" />
                  {
                      error["socialMediaUrl"] && (
                        <div className="py-2 text-sm text-red-500 font-bold">
                          {error["socialMediaUrl"]}
                        </div>
                      )
                    }
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-semibold text-gray-900 dark:text-white">
                  Email *
                </label>
                <div className="mt-1">
                  <input id="email" name="email" type="email" autoComplete="email"
                    onChange={e => onFieldChange(e, "email")}
                    className="block w-full px-4 py-3 font-medium text-gray-900 border-gray-300 rounded-lg shadow-sm dark:text-white focus:ring-secondary-500 focus:border-secondary-500" />
                  {
                      error["email"] && (
                        <div className="py-2 text-sm text-red-500 font-bold">
                          {error["email"]}
                        </div>
                      )
                    }
                </div>
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-semibold text-gray-900 dark:text-white">
                  Phone *
                </label>
                <div className="mt-1">
                  <input type="text" name="phone" id="phone" autoComplete="tel"
                    onChange={e => onFieldChange(e, "phoneNumber")}
                    className="block w-full px-4 py-3 font-medium text-gray-900 border-gray-300 rounded-lg shadow-sm dark:text-white focus:ring-secondary-500 focus:border-secondary-500" />
                  {
                      error["phoneNumber"] && (
                        <div className="py-2 text-sm text-red-500 font-bold">
                          {error["phoneNumber"]}
                        </div>
                      )
                    }
                </div>
              </div>

              <div className="sm:col-span-2">
                <button type="submit"
                  className="inline-flex items-center px-8 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-lg shadow-lg bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:focus:ring-primary-400 disabled:opacity-50"
                  disabled={!data.email || !data.name || !data.phoneNumber || isAnyError || isLoading}
                >
                  {
                    isLoading && (
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    )
                  }
                  Submit
                </button>

                {
                  isSubmitted && (
                    <span className="text-md text-green-800 font-bold p-3">
                      Submitted the details successfully!
                    </span>
                  )
                }
              </div>
            </form>
          </div>

          <div>
            <img className="block w-full mx-auto dark:hidden" src={CTAMockupLight} alt="Gift coins and plan upgrade" />
            <img className="hidden w-full mx-auto dark:block" src={CTAMockupDark} alt="Gift coins and plan upgrade" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default EarlyAccess;
