import * as React from "react";

import SuperHeader from "../components/superHeader";
import Header from './../components/header';
import InnerLayout from '../components/innerLayout';
import Hero from "../modules/home/hero";
import Features from "../modules/home/features";
import Content from '../modules/home/content';
import EarlyAccess from "../components/earlyAccess";
import Footer from './../components/footer';
import useComposeUrl from "../hooks/useComposeUrl";
import useMetaImage from "../hooks/useMetaImage";

const Home = (location) => {
  const currentPageUrl = useComposeUrl(location.pathname);
  const d = useMetaImage();
  console.log(d);

  return (
    <>
      <SuperHeader
        title="Starbeat"
        metaTitle="Starbeat - Creator’s first & ad-free Social Media"
        metaDescription="Earn more doing what you love with social media designed around creators and their communities"
        currentUrl={currentPageUrl}
      />
      <Header />
      <InnerLayout>
        <Hero />
        <Features />
        <Content />
        <EarlyAccess />
      </InnerLayout>
      <Footer />
    </>
  );
};

export default Home;
