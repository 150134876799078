import React from 'react';
import { Link } from 'gatsby';

import DesignElementLight from '../../images/design-elements-light.svg';
import DesignElementDark from '../../images/design-elements-dark.svg';
import HeroElementLight from '../../images/hero-mockup-light.png';
import HeroElementDark from '../../images/hero-mockup-dark.png';

const Hero = () => (
  <section className="bg-white dark:bg-gray-900">
    <div className="pb-12 bg-gray-50 dark:bg-gray-800 sm:pb-16 lg:pb-20">
      <div className="relative">
        <div className="absolute inset-0 bg-white dark:bg-gray-900 h-1/2"></div>

        <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative overflow-hidden rounded-3xl bg-secondary-100 dark:bg-secondary-900">

            <div className="absolute hidden right-4 top-8 lg:block">
              <img className="block w-auto h-64 dark:hidden" src={DesignElementLight} alt="Image depicting starbeat features" />
              <img className="hidden w-auto h-64 dark:block" src={DesignElementDark} alt="Image depicting starbeat features" />
            </div>

            <div className="absolute hidden lg:block right-12 xl:right-32 -bottom-60 xl:-bottom-32">
              <img className="block w-full max-w-xs mx-auto rotate-3 dark:hidden" src={HeroElementLight}
                alt="Image depicting starbeat user profile" />
              <img className="hidden w-full max-w-xs mx-auto rotate-3 dark:block" src={HeroElementDark}
                alt="Image depicting starbeat user profile" />
            </div>

            <div className="relative px-6 py-12 sm:px-12 sm:py-16 lg:px-16 lg:py-20 xl:px-20 xl:py-28">
              <div className="text-center lg:w-1/2 lg:text-left">
                <p className="text-sm font-bold tracking-widest uppercase text-secondary-500 dark:text-secondary-400">
                  Empowering Creators, uniting fans
                </p>
                <h1
                  className="mt-6 text-4xl font-bold text-gray-900 dark:text-white sm:text-5xl lg:text-6xl sm:tracking-tight">
                  Creator’s-First, Ad-Free Social Media
                </h1>
                <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200 lg:text-xl">
                  Create subscriptions, host live events, video sessions, polls, sell Merchandise, NFTs and more!
                </p>

                <div className="flex items-center justify-center mt-8 space-x-5 lg:justify-start lg:mt-12">
                  <Link
                    to="/creators"
                    title="I am a Creator"
                    className="inline-flex items-center px-6 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-lg shadow-sm bg-secondary-500 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                    role="button">
                    I'm a Creator
                  </Link>

                  <Link
                    to="/fans"
                    title="I am a Fan"
                    className="inline-flex items-center px-6 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-lg shadow-lg bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:bg-primary-400 dark:hover-bg-primary-500 dark:focus:ring-primary-400"
                    role="button">
                    I'm a Fan
                  </Link>
                </div>
              </div>

              <div className="-mt-32 lg:hidden">
                <img className="block w-full max-w-xs mx-auto -translate-x-1 translate-y-48 rotate-3 dark:hidden"
                  src={HeroElementLight} alt="Image depicting starbeat user profile" />
                <img className="hidden w-full max-w-xs mx-auto -translate-x-1 translate-y-48 dark:block rotate-3"
                  src={HeroElementDark} alt="Image depicting starbeat user profile" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
